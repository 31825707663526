<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card :to="`/project/${project.id}`" height="300" width="100%">
        <v-img
          :src="project.featuredImage"
          height="100%"
        >
        </v-img>
        <v-slide-y-transition>
          <v-overlay v-if="hover" absolute >
            <h2>{{ project.name }}</h2>
          </v-overlay>
        </v-slide-y-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
