<template>
  <v-row class="white--text mb-6" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : 'px-6 my-auto'">
    <v-col>
      <h2 class="display-1" v-html="banner.title" />
      <p
        class="font-weight-light body-1"
        v-for="d in banner.description.p"
        :key="d.i"
        v-html="d"
      >
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
