<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <v-img
          :src="heroImg()"
          min-height="80vh"
          max-height="80vh"
          max-width="100vw"
          class="pb-6"
          dark
        >
          <hero :title="banner.title" :subtitle="banner.description.sub" />
        </v-img>
      </v-col>
    </v-row>
    <v-row v-if="$route.params.id == 'architectural-woodwork'">
      <v-col cols="9" md="2" class="d-flex align-center justify-center pt-0">
        <label class="white--text">FILTER</label>
      </v-col>
      <v-col cols="3" md="10" class="pt-0">
        <v-tabs
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="type"
          background-color="transparent"
          class="mx-0"
          fixed-tabs
          dark
          color="primary"
        >
          <v-tab
            @click="filterCategories(tab.value)"
            v-for="tab in tabs"
            :key="tab.value"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-menu offset-y v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="my-4" color="primary" dark v-bind="attrs" v-on="on">
              mdi-filter
            </v-icon>
          </template>
          <v-list v-model="category">
            <v-list-item-group v-model="category" color="primary">
              <v-list-item
                :value="item.value"
                v-for="(item, index) in menuItems"
                :key="index"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : 'mx-0'"
      class="d-flex flex-wrap align-center my-10"
    >
      <v-col cols="12" sm="6" md="4">
        <first-banner :banner="banner" />
      </v-col>
      <v-col
        class="fill-height"
        cols="12"
        sm="6"
        md="4"
        v-for="item in filteredProjects"
        :key="item.i"
      >
        <project :project="item" class="my-auto" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Hero from "@/components/Page/Hero";
import FirstBanner from "../components/OurWork/FirstBanner.vue";
import Project from "../components/OurWork/Project.vue";
import { storage, projectsCollection } from "../firebase/credentials.js";
export default {
  components: {
    Hero,
    FirstBanner,
    Project,
  },
  data() {
    return {
      category: "all",
      projects: [],
      type: 1,
      filteredProjects: [],
      menuItems: [
        { title: "All", value: "all" },
        { title: "Residential", value: "residential" },
        { title: "Commercial", value: "commercial" },
        { title: "Hospitality", value: "hospitality" },
        { title: "Medical/Institutional", value: "medical-institutional" },
      ],
      tabs: [
        { title: "All", value: "all" },
        { title: "Residential", value: "residential" },
        { title: "Commercial", value: "commercial" },
        { title: "Hospitality", value: "hospitality" },
        { title: "Medical/Institutional", value: "medical-institutional" },
      ],
      hero: {
        title: "Let's build a better",
        titleColored: "Dirt world",
        subtitle:
          "Irure in sunt exercitation sit nulla tempor ex in magna pariatur mollit in.",
      },
      banner: {
        title: this.$route.params.id.toUpperCase(),
        description: this.$store.state.tags[this.$route.params.id],
      },
    };
  },
  methods: {
    heroImg() {
      let img = "";
      switch (this.banner.title.toLowerCase()) {
        case "residential":
          img = this.$vuetify.breakpoint.mdAndUp
            ? "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1800/v1657977087/Heros/Residential_Gallery-min_yropqx.webp"
            : "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_800/v1657977087/Heros/Residential_Gallery-min_yropqx.webp";
          break;
        case "commercial":
          img = this.$vuetify.breakpoint.mdAndUp
            ? "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1800/v1657977087/Heros/Commercial_Gallery-min_ry5nn0.webp"
            : "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_800/v1657977087/Heros/Commercial_Gallery-min_ry5nn0.webp";
          break;
        case "public works":
          img = this.$vuetify.breakpoint.mdAndUp
            ? "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1800/v1657977088/Heros/PublicWorks_Gallery-min_us7ku0.webp"
            : "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_800/v1657977088/Heros/PublicWorks_Gallery-min_us7ku0.webp";
          break;
        default:
          img = this.$vuetify.breakpoint.mdAndUp
            ? "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1800/v1656043794/Woodshop/TopBanner5_canjir.webp"
            : "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_800/v1656043794/Woodshop/TopBanner5_canjir.webp";
          break;
      }
      return img;
    },
    filterCategories(val) {
      if (val != "all") {
        this.filteredProjects = this.projects.filter((e) => e.category == val);
      } else if (val == "all") {
        this.filteredProjects = this.projects;
      }
    },
    async getProjects() {
      this.getBanner();
      try {
        const querySnapshot = await projectsCollection
          .where("tag", "==", this.$route.params.id)
          .where("published", "==", true)
          .get();
        querySnapshot.forEach(async (doc) => {
          let img = "";
          if (doc.data().featured != null) {
            img = await storage
              .ref()
              .child(doc.data().featured)
              .getDownloadURL();
          }
          this.projects.push({
            id: doc.id,
            name: doc.data().name,
            featuredImage: img,
            tag: doc.data().tag,
            category: doc.data().category,
          });
          this.filteredProjects = this.projects;
          if (this.$route.params.type) {
            this.filterCategories(this.$route.params.type)
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    getBanner() {
      if (
        this.$route.params.id == "public-works" ||
        this.$route.params.id == "Public-Works"
      ) {
        this.banner.description = this.$store.state.tags["publicWorks"];
        this.banner.title = "PUBLIC WORKS";
      } else if (
        this.$route.params.id == "architectural-woodwork" ||
        this.$route.params.id == "architectural-woodwork"
      ) {
        this.banner.description =
          this.$store.state.tags["architecturalwoodwork"];
        this.banner.title =
          '<span class="primary--text">JLR</span> Architectural Woodwork';
      } else {
        this.banner.description = this.$store.state.tags[this.$route.params.id];
        this.banner.title = this.$route.params.id.toUpperCase();
      }
    },
  },
  async mounted() {
    switch (this.$route.params.type) {
      case 'residential':
        this.type = 1
        break;
      case 'commercial':
        this.type = 2
        break;
      case 'hospitality':
        this.type = 3
        break;
      case 'medical-institutional':
        this.type = 4
        break;
      default:
        this.type = 0
        break;
    }
    await this.getProjects();
  },
  watch: {
    $route() {
      if (this.filteredProjects.length > 0) {
        this.filteredProjects = [];
        this.projects = [];
      }
      this.getBanner();
      this.getProjects();
    },
  },
};
</script>

<style lang="scss" scoped></style>
